.path {
  height: 100vh;
  // margin-top: 10%;
  padding: 0 15%;
  background-image: url("../../assets/homebackground.png");

  .stepper {
    padding-top: 15%;
    display: flex;
    flex-direction: column;
    font-size: small;
  }
  .step-btn {
    // margin: 5% 0;
    padding: 0 7% 0 0;

    display: flex;
    justify-content: flex-end;
    gap: 1%;
  }
  .tabContainer {
    padding: 3% 5% 0 5%;

    .webpage {
      height: 54vh;
      border-radius: 40px;

      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 7%;
      justify-content: center;
    }
    .document {
      height: 57vh;
      border-radius: 40px;

      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
    .webpage {
      .searchContent {
        width: 90%;
        display: flex;
        flex-direction: column;
        /* justify-content: center; */
        align-items: center;

        .searchField {
          width: 100%;
        }
      }

      .searchbar {
        display: flex;
        align-items: center;
        gap: 5%;
      }
      .importlink {
        color: #001a5c;
      }
      .scan-btn {
        margin-top: 3%;
        width: 20vw;
      }
      .css-dihucs-MuiContainer-root {
        width: 100%;
        margin-left: auto;
        box-sizing: border-box;
        margin-right: auto;
        display: block;
        padding-left: 16px;
        padding-right: 16px;
      }
    }

    .document {
      .insideDropzone {
        display: flex;
        flex-direction: column;
        justify-content: center;
      }

      .MuiDropzoneArea-text {
        margin: 0;
      }

      .MuiDropzoneArea-root {
        width: 587px;
        display: flex;
        justify-content: center;
        min-height: 100px;
        height: 140px;
      }
      .MuiDropzoneArea-textContainer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        align-items: center;
      }
      .uploadBtn {
        margin: 5% 0;
        display: flex;
        justify-content: flex-end;
        gap: 6%;
      }
    }

    .textarea {
      position: relative;
      margin-left: 30%;
      padding: 2%;
      margin-top: 280px;
      border: 2px inset #001a5c;
      background-color: #ffffff;
      width: 500px;
      height: 120px;
      overflow: auto;

      .icon {
        cursor: pointer;

        position: absolute;
        top: 2px;
        right: 2px;
      }
    }
  }
}

.playground {
  height: 57vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 7%;
  padding: 2%;
  .css-aquzwr-MuiInputBase-root-MuiOutlinedInput-root {
    border-radius: 50px !important;
  }
  .website-textfield {
    color: #001a5c;
  }
}
