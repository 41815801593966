.allBot {
  background-image: url("../../assets/homebackground.png");
  // background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  width: 100vw;

  .css-ahj2mt-MuiTypography-root {
    font-weight: bolder !important;
  }
  padding-top: 10%;
  .card-box {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2% 0% 0 0%;
    // height: 70vh;
    padding: 4% 4.5%;

    border-radius: 37px;
    gap: 12px;

    .create-button-box {
      position: absolute;
      display: flex;
      top: -19px;
      justify-content: center;
      width: 98%;

      .create-btn {
        width: 62%;
        padding: 1.2%;
        // background: linear-gradient(
        //   135deg,
        //   rgba(255, 255, 255, 0.1),
        //   rgba(255, 255, 255, 0)
        // );
        background: radial-gradient(
          97.57% 210.75% at 0.9% 2.98%,
          rgba(255, 255, 255, 0.4) 0%,
          rgba(255, 255, 255, 0) 100%
        );

        box-shadow: 0px 6px 18px 0px rgba(0, 0, 0, 0.25);

        border: 1px solid rgba(255, 255, 255, 0.18);
      }
      .create-btn:hover {
        width: 62%;
        padding: 1.2%;
        background: rgba(1, 26, 89, 1);

        backdrop-filter: black;
        box-shadow: 0px 6px 18px 0px rgba(0, 0, 0, 0.25);

        border: 1px solid rgba(255, 255, 255, 0.18);
        color: #fff;
      }
    }
  }

  .bot-card {
    // height: 300px;
    margin: 4%;
    padding: 2%;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: linear-gradient(
      135deg,
      rgba(255, 255, 255, 0.1),
      rgba(255, 255, 255, 0)
    );
    backdrop-filter: blur (40px);
    -webkit-backdrop-filter: blur (20px);
    box-shadow: 2px 2px 15px -1px rgba(0, 0, 0, 0.25);

    .bot-card-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      // justify-content: space-around;

      gap: 15px;
      // padding: 2% 5%;
    }
  }
  .bot-card:hover {
    transform: scale(1.1);
    background: rgba(255, 255, 255, 0.21);
    // border-radius: 16px;
    // box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    // backdrop-filter: blur(20px);
    // -webkit-backdrop-filter: blur(20px);
    // border: 1px solid rgba(255, 255, 255, 0.3);
  }
  .bot-card-div {
    display: flex;
    flex-direction: column;
  }
}

.enter-name {
  font-weight: 700;
  margin: 4%;
}
