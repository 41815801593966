.assessment {
  background-image: url("../../assets/homebackground.png");
  // background-attachment: fixed;
  background-repeat: repeat;
  background-size: cover;

  padding: 7% 2% 2% 2%;
  gap: 5%;
  .arrow-btn {
    cursor: pointer;
    width: 40px;
  }
  .result {
    margin-top: 1%;
  }

  .table-cell-style {
    font-size: 20px;
    font-family: "Poppins", sans-serif;
  }
}
