.home-screen {
  // background: url("../../assets/homebackground.png");

  height: 100vh;
  padding-top: 100px;

  // .card-biobot:hover {
  //   .card-content:hover {
  //     color: #ffffff;
  //   }
  // }

  video {
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: calc(100vh - 100px);
  }
  .video-wrapper {
    // border: 2px solid #000;
    // width: 400px;
    height: 100%;
    position: relative;
    // overflow: hidden;
    // text-align: center;
    // display: flex;
    // align-items: center;
    // justify-content: center;
  }
  .header {
    position: relative;
    background: linear-gradient(180deg, rgba(0, 116, 252, 0) 20%, #004596 100%);
    height: calc(100vh - 100px);
  }
  .section2 {
    display: flex;
    justify-content: center;
    gap: 5vw;
    margin-top: 8%;
    padding: 2%;
  }
  .heading {
    color: rgba(0, 26, 92, 1);
    text-align: center;
  }
  .card-biobot {
    display: flex;
    // width: 100%;
    justify-content: space-evenly;
    // height: 320px;
    // height: 260px;

    border-radius: 10px;
    // width: 400px;
    min-width: 340px;
    height: 400px;

    .home-card {
      border-radius: 10px;

      padding: 10px 10px 0 10px;

      border: 0.5px solid #fff;
      background: linear-gradient(112deg, #648fae 0.55%, #395e85 100.56%);
      // background: linear-gradient(112deg, #265679 0.55%, #0e3863 100.56%);
      // background: linear-gradient(112deg, #153d7f 0.55%, #04203d 100.56%);

      // background: linear-gradient(
      //   112deg,
      //   #6d9ec0 0.55%,
      //   rgba(0, 0, 0, 0.3) 100.56%
      // );
      // background: #395e85;

      backdrop-filter: blur(10px);
    }

    // .css-1ri6ub7-MuiPaper-root-MuiCard-root,
    // .css-w88nxk {
    //   background: linear-gradient(156.41deg, #ffffff 21.84%, #d8eaff 91.45%);
    //   height: 260px;
    // }
    // .hr-tag {
    //   background-color: rgba(0, 116, 252, 1);
    //   height: 3px;
    // }
    .card-content {
      height: 400px;
      .card-content-h5 {
        color: #fff;
        font-feature-settings:
          "clig" off,
          "liga" off;
        font-family: "Poppins", sans-serif;
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        position: relative;
        margin: 16px 0;
      }

      .card-content-p {
        color: #fff;
        font-feature-settings:
          "clig" off,
          "liga" off;
        font-family: "Poppins", sans-serif;
        position: relative;
        font-size: 18px;
        font-weight: 400;
        line-height: 28px; /* 175% */
        margin: 16px 0;
      }
      img {
        // min-height: 230px;
        height: 45%;
        width: 100%;
        border-radius: 15px;
      }
    }
    .css-vj1n65-MuiGrid-root {
    }
  }
  .card-surveybot {
    display: flex;
    // width: 100%;
    justify-content: space-evenly;
    // height: 320px;
    height: 260px;
    .css-1ri6ub7-MuiPaper-root-MuiCard-root,
    .css-w88nxk {
      background: linear-gradient(156.41deg, #ffffff 21.84%, #d8eaff 91.45%);
      height: 260px;
    }
    .hr-tag {
      background-color: rgba(0, 116, 252, 1);
      height: 3px;
    }
    .card-content {
      color: rgba(0, 26, 92, 1);
    }
    .css-vj1n65-MuiGrid-root {
    }
  }
  .card-biobot1 {
    display: flex;
    width: 100%;
    justify-content: space-around;
    // height: 320px;
    height: 260px;
    .css-1ri6ub7-MuiPaper-root-MuiCard-root,
    .css-w88nxk {
      background: linear-gradient(156.41deg, #ffffff 21.84%, #d8eaff 91.45%);
      height: 260px;
    }
    .hr-tag {
      background-color: rgba(0, 116, 252, 1);
      height: 3px;
    }
    .card-content {
      color: rgba(0, 26, 92, 1);
      position: relative;
    }
    .css-vj1n65-MuiGrid-root {
    }
  }
  .card-biobot:hover {
    cursor: pointer;
    // .css-1ri6ub7-MuiPaper-root-MuiCard-root,
    // .css-w88nxk {
    //   background: rgba(0, 26, 92, 1);
    // }
    // .hr-tag {
    //   background-color: rgba(0, 116, 252, 1);
    //   height: 3px;
    // }
    // .card-content {
    //   color: #fff;
    // }
    // .images {
    //   filter: invert(35%) sepia(0%) saturate(275%) hue-rotate(367deg)
    //     brightness(300%) contrast(200%);
    //   // filter: grayscale(100%);
    // }
  }
  .card-surveybot:hover {
    cursor: pointer;
    .css-1ri6ub7-MuiPaper-root-MuiCard-root,
    .css-w88nxk {
      background: rgba(0, 26, 92, 1);
    }
    .hr-tag {
      background-color: rgba(0, 116, 252, 1);
      height: 3px;
    }
    .card-content {
      color: #fff;
    }
    .images {
      filter: invert(35%) sepia(0%) saturate(275%) hue-rotate(367deg)
        brightness(300%) contrast(200%);
      // filter: grayscale(100%);
    }
  }
}
