.upload-section {
  display: flex;
  min-height: 60vh;
  border-radius: 2vw;
  justify-content: center;
  align-items: center;

  &-dotted {
    padding-top: 0;
    padding-bottom: 0;
    width: 60%;
    align-self: center;

    .insideDropzone {
      display: flex;
      flex-direction: column;
      justify-content: center;

      .file-btn {
        align-self: center;
        margin-top: 2vw;
        height: 2.2vw;
        border-radius: 100px;
        background: linear-gradient(90deg, #004596 0%, #0074fc 100%);
        text-transform: capitalize;
        color: #ffffff;
        padding: 5px 20px;
        font-family: "Poppins", sans-serif;
        font-size: 18px;
        font-weight: 700;
      }
    }

    .MuiDropzoneArea-text {
      margin: 0;
    }

    .MuiDropzoneArea-root {
      width: auto;
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 400px;

      border-radius: 16px;
      border: 1px solid rgba(255, 255, 255, 0.49);
      background: linear-gradient(
        80deg,
        rgba(0, 0, 0, 0.42) 1.78%,
        #0d54a5 102.77%
      );
      backdrop-filter: blur(10px);
    }

    .MuiDropzoneArea-textContainer {
      display: flex;
      flex-direction: column-reverse;
      justify-content: center;
      text-align: center;
      align-items: center;
      margin-bottom: 20px;

      .MuiDropzoneArea-icon {
        color: #ffffff !important;
      }
    }

    .uploadBtn {
      margin: 5% 0;
      display: flex;
      justify-content: flex-end;
      gap: 6%;
    }
  }

  .waitResp {
    width: 20vw;
    animation: anim 1s steps(10) infinite;
  }

  .draft-container {
    width: 100%;
    min-height: 50vw;
    display: flex;
    gap: 1%;

    .pdf-section {
      // height: 75vh;
      width: 37%;
      overflow: hidden;
      padding: 2%;

      .pdf-heading {
        margin-bottom: 3.5vh;
      }

      .pdf-viewer {
        height: 98%;
        border-radius: 10ox;
        background-color: white;
        border-radius: 10px;
      }
    }

    .download-section,
    .loader {
      // height: 80vh;
      width: 65%;
      overflow: auto;
      padding: 2%;
      // background-color: white;
      display: flex;
      flex-direction: column;

      .download-btn-box {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 2vh;

        .dwnd-btn {
          display: flex;
          width: 10vw;
          border: none;
          border-radius: 32px;
          font-weight: bold;
          align-self: end;
          background-color: #001a5c;
          color: white;
          gap: 1vw;
          // height: 2.5vw;
          justify-content: center;
          align-items: center;
          box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.5);
        }
      }

      .docs {
        background-color: white;
        margin-bottom: 2%;
        padding: 3%;
        // height: 98%;
        border-radius: 10px;
        text-wrap: wrap;
        overflow: auto;
        font-family: "Inter";
      }

      .docs::-webkit-scrollbar {
        display: none;
      }
    }

    .download-section::-webkit-scrollbar {
      display: none;
    }

    .loader {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: white;
    }
  }

  .MuiGrid-container {
    justify-content: center;

    .MuiGrid-item {
      .MuiChip-outlined {
        color: #fff;
        font-family: "Poppins", sans-serif;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        background: linear-gradient(
          80deg,
          rgba(0, 0, 0, 0.42) 178%,
          #094f9f 102.77%
        );
      }
    }
  }
}

.pdf-page {
  padding: 8% 2% 0.5% 2%;
  background-image: url("../../assets/section1.png");
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;

  .arrow-btn {
    width: 40px;
    cursor: pointer;
    margin-bottom: 7px;
  }
}

.btn-div {
  display: flex;
  justify-content: center;
  margin-top: 0;
  margin-left: 0;
  width: 100%;

  font-size: large;

  .file-btn {
    align-self: center;
    border-radius: 100px;
    background: linear-gradient(90deg, #004596 0%, #0074fc 100%);
    text-transform: capitalize;
    color: #ffffff;
    padding: 10px 100px;
    font-family: "Poppins", sans-serif;
    font-size: 22px;
    font-weight: 700;
  }
}

.summary-wrap {
  padding: 6% 2% 0.5% 2%;
  background-image: url("../../assets/section1.png");
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .summary-container {
    // height: 75vh;
    overflow: auto;
    padding: 2%;
    width: 90%;
    // margin-left: 18%;
    border-radius: 20px;
    border: 1px solid rgba(255, 255, 255, 0.49);
    background: linear-gradient(
      80deg,
      rgba(0, 0, 0, 0.42) 1.78%,
      #094f9f 102.77%
    );
    backdrop-filter: blur(12.287334442138672px);

    // tbody {
    //   line-height: normal;
    //   white-space: pre-wrap;
    //   height: 55vh;
    //   overflow: auto;
    // }

    // tr {
    //   display: flex;
    //   width: 100%;
    //   justify-content: space-evenly;
    // }

    // td {
    //   display: flex;
    //   width: 30%;
    //   justify-content: flex-start;
    //   color: #fff;
    //   font-family: "Poppins", sans-serif;
    //   font-size: 20px;
    //   font-style: normal;
    //   font-weight: 500;
    // }

    // th {
    //   display: flex;
    //   width: 50%;
    //   justify-content: center;
    //   color: #fff;
    //   font-family: "Poppins", sans-serif;
    //   font-size: 30px;
    //   font-style: normal;
    //   font-weight: 700;
    // }
  }

  .MuiTabs-flexContainer {
    display: flex;
    flex-direction: row-reverse;
    justify-content: end;
    // padding: 200px;
  }

  .title {
    color: #fff;
    font-family: "Poppins", sans-serif;
    font-size: 44px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    margin-right: auto;
  }

  .tab-name {
    color: #fff !important;
    font-family: "Poppins", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
  }

  .tab-content {
    color: #fff;
    font-family: "Poppins", sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    white-space: pre-wrap;
    height: 55vh;
    overflow: auto;
  }

  .table-header-text {
    color: #fff;
    font-family: "Poppins", sans-serif;
    font-size: 20px;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0em;
    background-color: transparent;
  }

  .table-row-text {
    color: #fff;
    font-family: "Poppins", sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    background-color: transparent;
  }
}

.MuiChip-outlined {
  color: #fff;
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  background: linear-gradient(80deg, rgba(0, 0, 0, 0.42) 178%, #094f9f 102.77%);
}
