.csv-analytic-main {
  background-image: url("../../assets/section1.svg");
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  width: 100vw;
  box-sizing: border-box;
  padding-top: 10%;
  overflow-x: hidden;

  .analytic-inner {
    height: fit-content;
    width: 80%;
    margin-left: 10%;

    min-height: 100%;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    // justify-content: center;
    // align-items: center;

    // background-color: black;
    .upload-section-dotted {
      padding-top: 6%;
      padding-bottom: 6%;
      width: 60%;
      align-self: center;

      .insideDropzone {
        display: flex;
        flex-direction: column;
        justify-content: center;
      }

      .MuiDropzoneArea-text {
        margin: 0;
      }

      .MuiDropzoneArea-root {
        width: auto;
        display: flex;
        justify-content: center;
        border-radius: 2vw;
        height: 80%;
      }
      .MuiDropzoneArea-textContainer {
        display: flex;
        flex-direction: column-reverse;
        justify-content: center;
        text-align: center;
        align-items: center;
      }
    }

    .MuiButtonBase-root.MuiTab-root {
      width: 100%;
      font-weight: 900;
      font-size: large;
    }
    .MuiTabs-flexContainer {
      justify-content: center;
    }

    .MuiTypography-subtitle1 {
      display: none;
    }

    .MuiGrid-spacing-xs-1 > .MuiGrid-item {
      margin: 30px auto 0 auto;
    }

    .MuiDropzonePreviewList-removeButton {
      display: none;
    }

    .text-card-wrapper {
      // background-color: red;
      height: fit-content;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      padding: 5%;

      .card-inner-wrapper {
        border: 1px solid rgba(93, 144, 156, 0);
        border-radius: 10px;
        box-shadow: rgba(0, 0, 0, 0.56) 0px 22px 70px 4px;
        height: fit-content;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 50px;
        padding-top: 30px;
        padding-bottom: 20px;
      }

      .MuiPaper-root.MuiCard-root {
        background: radial-gradient(
          circle at 1.3% 2.8%,
          rgb(239, 249, 249) 0%,
          rgb(162, 187, 228) 100.2%
        );
        color: #001a5c;

        width: 90%;
        min-height: 60px;
        border-radius: 10px;
        margin-bottom: 10px;
        box-shadow:
          rgba(0, 0, 0, 0.4) 2px 2px 4px,
          rgba(0, 0, 0, 0.3) 2px 7px 13px -3px,
          rgba(0, 0, 0, 0.2) 2px -3px 0px inset;

        * {
          // font-weight: 600 !important;
        }
      }
    }
  }
}

.spinner {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 70px;
}

.spinner > h3 {
  margin-right: 10px;
}

.spinner > div {
  width: 10px;
  height: 10px;
  background-color: #333;
  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.spinner .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
  }
}

@keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
