.analytic-main {
  background-image: url("../../assets/section1.svg");
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  width: 100vw;
  box-sizing: border-box;
  padding-top: 10%;
  overflow-x: hidden;
  // overflow: hidden;

  .analytic-inner {
    height: fit-content;
    width: 100%;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    // justify-content: center;
    // align-items: center;

    // background-color: black;

    .word-cloud-wrapper {
      height: fit-content;
      width: 100%;
      margin-bottom: 5%;
      display: flex;
      flex-direction: column;
      align-items: center;
      img {
        margin-top: 2%;
        height: 45vh;
        width: 40vw;
        // border: 1px solid black;
        border-radius: 10px;
        border: 1px solid rgba(93, 144, 156, 0);
        // box-shadow: rgba(0, 0, 0, 0.56) 0px 22px 70px 4px;
      }
    }

    .pie-chart-wrapper {
      // background-color: orange;

      height: fit-content;
      //   margin: 0 10%;
      display: flex;
      flex-direction: column;
      align-items: center;

      img,
      .pie-chart-outer {
        display: flex;
        justify-content: space-around;
        padding: 30px 0;

        width: 90%;
        // border-radius: 10px;
        // border: 1px solid black;
        // border: 1px solid rgba(93, 144, 156, 0);
        // box-shadow: rgba(0, 0, 0, 0.56) 0px 22px 70px 4px;
        border-radius: 16px;
        border: 1px solid #afafaf;
        // background: linear-gradient(
        //   80deg,
        //   rgba(0, 0, 0, 0.42) 1.78%,
        //   rgba(20, 20, 20, 0) 102.77%
        // );
        background-color: #f3f5f9;
        backdrop-filter: blur(10px);
      }
    }

    .text-card-wrapper {
      // background-color: red;
      height: fit-content;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      // padding: 5%;

      .card-inner-wrapper {
        border: 1px solid rgba(93, 144, 156, 0);
        border-radius: 10px;
        // box-shadow: rgba(0, 0, 0, 0.56) 0px 22px 70px 4px;
        height: fit-content;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        // margin-bottom: 50px;
        // padding-bottom: 20px;
      }

      p {
        width: 100%;
        font-size: 30px;
        text-align: left;
        padding: 30px 0 20px 5%;
        // -webkit-text-stroke-width: thick;
        letter-spacing: 0.1em;
        font-weight: 600;
      }

      .MuiPaper-root.MuiCard-root {
        background: radial-gradient(
          circle at 1.3% 2.8%,
          rgb(239, 249, 249) 0%,
          rgb(162, 187, 228) 100.2%
        );
        color: #001a5c;
        width: 100%;
        padding: 2px;
        min-height: 60px;
        border-radius: 10px;
        margin-bottom: 10px;
        font-size: large;
        box-shadow:
          rgba(0, 0, 0, 0.4) 2px 2px 4px,
          rgba(0, 0, 0, 0.3) 2px 7px 13px -3px,
          rgba(0, 0, 0, 0.2) 2px -3px 0px inset;

        * {
          // font-weight: 600 !important;
        }
      }
    }
  }

  .tab-heading {
    text-transform: capitalize;
    font-size: 24px;
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    // width: 500px;
  }
}

div.apexcharts-legend.apx-legend-position-right {
  align-items: end;
}
